<template>
  <div class="container-xl">
    <detailed-project :uuid="uuid" back-button="true"/>
  </div>
</template>

<script>
import DetailedProject from "@/components/common/components/DetailedProject";

export default {
  name: "ArchivedProjectDetails",
  components: {
    DetailedProject
  },
  data() {
    return {
      uuid: this.$route.params.uuid,
    }
  }
}
</script>